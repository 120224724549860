import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
   ..
    </span> 
   
  `,
})
export class FooterComponent {

// last modified on 03-November-2022
// moved on 27-06-2023
 // Test PowerPay  Jan. Patch V 1.0 ( AS )

//  © 2024, Powered by VSERV Infosystems Private Limited (V 8.8)
}
